import * as i0 from '@angular/core';
import { NgZone, PLATFORM_ID, Injectable, Inject, InjectionToken, inject, INJECTOR, ɵglobal, ErrorHandler, Optional, SkipSelf, NgModule, APP_BOOTSTRAP_LISTENER } from '@angular/core';
import * as i5 from '@ngxs/store/internals';
import { memoize, INITIAL_STATE_TOKEN, NgxsBootstrapper, ɵNGXS_STATE_CONTEXT_FACTORY, ɵNGXS_STATE_FACTORY } from '@ngxs/store/internals';
import { isPlatformServer } from '@angular/common';
import { Observable, Subject, BehaviorSubject, of, forkJoin, throwError, EMPTY, from, isObservable } from 'rxjs';
import { filter, map, share, shareReplay, take, exhaustMap, mergeMap, defaultIfEmpty, catchError, takeUntil, distinctUntilChanged, tap, startWith, pairwise } from 'rxjs/operators';

/**
 * Returns the type from an action instance/class.
 * @ignore
 */
function getActionTypeFromInstance(action) {
  if (action.constructor && action.constructor.type) {
    return action.constructor.type;
  } else {
    return action.type;
  }
}
/**
 * Matches a action
 * @ignore
 */
function actionMatcher(action1) {
  const type1 = getActionTypeFromInstance(action1);
  return function (action2) {
    return type1 === getActionTypeFromInstance(action2);
  };
}
/**
 * Set a deeply nested value. Example:
 *
 *   setValue({ foo: { bar: { eat: false } } },
 *      'foo.bar.eat', true) //=> { foo: { bar: { eat: true } } }
 *
 * While it traverses it also creates new objects from top down.
 *
 * @ignore
 */
const setValue = (obj, prop, val) => {
  obj = Object.assign({}, obj);
  const split = prop.split('.');
  const lastIndex = split.length - 1;
  split.reduce((acc, part, index) => {
    if (index === lastIndex) {
      acc[part] = val;
    } else {
      acc[part] = Array.isArray(acc[part]) ? acc[part].slice() : Object.assign({}, acc[part]);
    }
    return acc && acc[part];
  }, obj);
  return obj;
};
/**
 * Get a deeply nested value. Example:
 *
 *    getValue({ foo: bar: [] }, 'foo.bar') //=> []
 *
 * @ignore
 */
const getValue = (obj, prop) => prop.split('.').reduce((acc, part) => acc && acc[part], obj);
/**
 * Simple object check.
 *
 *    isObject({a:1}) //=> true
 *    isObject(1) //=> false
 *
 * @ignore
 */
const isObject$1 = item => {
  return item && typeof item === 'object' && !Array.isArray(item);
};
/**
 * Deep merge two objects.
 *
 *    mergeDeep({a:1, b:{x: 1, y:2}}, {b:{x: 3}, c:4}) //=> {a:1, b:{x:3, y:2}, c:4}
 *
 * @param base base object onto which `sources` will be applied
 */
const mergeDeep = (base, ...sources) => {
  if (!sources.length) return base;
  const source = sources.shift();
  if (isObject$1(base) && isObject$1(source)) {
    for (const key in source) {
      if (isObject$1(source[key])) {
        if (!base[key]) Object.assign(base, {
          [key]: {}
        });
        mergeDeep(base[key], source[key]);
      } else {
        Object.assign(base, {
          [key]: source[key]
        });
      }
    }
  }
  return mergeDeep(base, ...sources);
};
function throwStateNameError(name) {
  throw new Error(`${name} is not a valid state name. It needs to be a valid object property name.`);
}
function throwStateNamePropertyError() {
  throw new Error(`States must register a 'name' property.`);
}
function throwStateUniqueError(current, newName, oldName) {
  throw new Error(`State name '${current}' from ${newName} already exists in ${oldName}.`);
}
function throwStateDecoratorError(name) {
  throw new Error(`States must be decorated with @State() decorator, but "${name}" isn't.`);
}
function throwActionDecoratorError() {
  throw new Error('@Action() decorator cannot be used with static methods.');
}
function throwSelectorDecoratorError() {
  throw new Error('Selectors only work on methods.');
}
function getZoneWarningMessage() {
  return 'Your application was bootstrapped with nooped zone and your execution strategy requires an actual NgZone!\n' + 'Please set the value of the executionStrategy property to NoopNgxsExecutionStrategy.\n' + 'NgxsModule.forRoot(states, { executionStrategy: NoopNgxsExecutionStrategy })';
}
function getUndecoratedStateInIvyWarningMessage(name) {
  return `'${name}' class should be decorated with @Injectable() right after the @State() decorator`;
}
function throwSelectFactoryNotConnectedError() {
  throw new Error('You have forgotten to import the NGXS module!');
}
function throwPatchingArrayError() {
  throw new Error('Patching arrays is not supported.');
}
function throwPatchingPrimitiveError() {
  throw new Error('Patching primitives is not supported.');
}
class DispatchOutsideZoneNgxsExecutionStrategy {
  constructor(_ngZone, _platformId) {
    this._ngZone = _ngZone;
    this._platformId = _platformId;
    // Caretaker note: we have still left the `typeof` condition in order to avoid
    // creating a breaking change for projects that still use the View Engine.
    if (typeof ngDevMode === 'undefined' || ngDevMode) {
      verifyZoneIsNotNooped(_ngZone);
    }
  }
  enter(func) {
    if (isPlatformServer(this._platformId)) {
      return this.runInsideAngular(func);
    }
    return this.runOutsideAngular(func);
  }
  leave(func) {
    return this.runInsideAngular(func);
  }
  runInsideAngular(func) {
    if (NgZone.isInAngularZone()) {
      return func();
    }
    return this._ngZone.run(func);
  }
  runOutsideAngular(func) {
    if (NgZone.isInAngularZone()) {
      return this._ngZone.runOutsideAngular(func);
    }
    return func();
  }
}
/** @nocollapse */
DispatchOutsideZoneNgxsExecutionStrategy.ɵfac = function DispatchOutsideZoneNgxsExecutionStrategy_Factory(t) {
  return new (t || DispatchOutsideZoneNgxsExecutionStrategy)(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(PLATFORM_ID));
};
/** @nocollapse */
DispatchOutsideZoneNgxsExecutionStrategy.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: DispatchOutsideZoneNgxsExecutionStrategy,
  factory: DispatchOutsideZoneNgxsExecutionStrategy.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DispatchOutsideZoneNgxsExecutionStrategy, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.NgZone
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }];
  }, null);
})();
// Caretaker note: this should exist as a separate function and not a class method,
// since class methods are not tree-shakable.
function verifyZoneIsNotNooped(ngZone) {
  // `NoopNgZone` is not exposed publicly as it doesn't expect
  // to be used outside of the core Angular code, thus we just have
  // to check if the zone doesn't extend or instanceof `NgZone`.
  if (ngZone instanceof NgZone) {
    return;
  }
  console.warn(getZoneWarningMessage());
}
const ROOT_OPTIONS = new InjectionToken('ROOT_OPTIONS');
const ROOT_STATE_TOKEN = new InjectionToken('ROOT_STATE_TOKEN');
const FEATURE_STATE_TOKEN = new InjectionToken('FEATURE_STATE_TOKEN');
const NGXS_PLUGINS = new InjectionToken('NGXS_PLUGINS');
const META_KEY = 'NGXS_META';
const META_OPTIONS_KEY = 'NGXS_OPTIONS_META';
const SELECTOR_META_KEY = 'NGXS_SELECTOR_META';
/**
 * The NGXS config settings.
 */
class NgxsConfig {
  constructor() {
    /**
     * Defining the default state before module initialization
     * This is convenient if we need to create a define our own set of states.
     * @deprecated will be removed after v4
     * (default: {})
     */
    this.defaultsState = {};
    /**
     * Defining shared selector options
     */
    this.selectorOptions = {
      injectContainerState: true,
      suppressErrors: true // TODO: default is true in v3, will change in v4
    };
    this.compatibility = {
      strictContentSecurityPolicy: false
    };
    this.executionStrategy = DispatchOutsideZoneNgxsExecutionStrategy;
  }
}
/** @nocollapse */
NgxsConfig.ɵfac = function NgxsConfig_Factory(t) {
  return new (t || NgxsConfig)();
};
/** @nocollapse */
NgxsConfig.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgxsConfig,
  factory: function NgxsConfig_Factory(t) {
    let r = null;
    if (t) {
      r = new t();
    } else {
      r = (options => mergeDeep(new NgxsConfig(), options))(i0.ɵɵinject(ROOT_OPTIONS));
    }
    return r;
  },
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsConfig, [{
    type: Injectable,
    args: [{
      providedIn: 'root',
      useFactory: options => mergeDeep(new NgxsConfig(), options),
      deps: [ROOT_OPTIONS]
    }]
  }], function () {
    return [];
  }, null);
})();
/**
 * Represents a basic change from a previous to a new value for a single state instance.
 * Passed as a value in a NgxsSimpleChanges object to the ngxsOnChanges hook.
 */
class NgxsSimpleChange {
  constructor(previousValue, currentValue, firstChange) {
    this.previousValue = previousValue;
    this.currentValue = currentValue;
    this.firstChange = firstChange;
  }
}
class NoopNgxsExecutionStrategy {
  enter(func) {
    return func();
  }
  leave(func) {
    return func();
  }
}
/** @nocollapse */
NoopNgxsExecutionStrategy.ɵfac = function NoopNgxsExecutionStrategy_Factory(t) {
  return new (t || NoopNgxsExecutionStrategy)();
};
/** @nocollapse */
NoopNgxsExecutionStrategy.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NoopNgxsExecutionStrategy,
  factory: NoopNgxsExecutionStrategy.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NoopNgxsExecutionStrategy, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * The strategy that might be provided by users through `options.executionStrategy`.
 */
const USER_PROVIDED_NGXS_EXECUTION_STRATEGY = new InjectionToken('USER_PROVIDED_NGXS_EXECUTION_STRATEGY');
/*
 * Internal execution strategy injection token
 */
const NGXS_EXECUTION_STRATEGY = new InjectionToken('NGXS_EXECUTION_STRATEGY', {
  providedIn: 'root',
  factory: () => {
    const injector = inject(INJECTOR);
    const executionStrategy = injector.get(USER_PROVIDED_NGXS_EXECUTION_STRATEGY);
    return executionStrategy ? injector.get(executionStrategy) : injector.get(typeof ɵglobal.Zone !== 'undefined' ? DispatchOutsideZoneNgxsExecutionStrategy : NoopNgxsExecutionStrategy);
  }
});

/**
 * Ensures metadata is attached to the class and returns it.
 *
 * @ignore
 */
function ensureStoreMetadata$1(target) {
  if (!target.hasOwnProperty(META_KEY)) {
    const defaultMetadata = {
      name: null,
      actions: {},
      defaults: {},
      path: null,
      makeRootSelector(context) {
        return context.getStateGetter(defaultMetadata.name);
      },
      children: []
    };
    Object.defineProperty(target, META_KEY, {
      value: defaultMetadata
    });
  }
  return getStoreMetadata$1(target);
}
/**
 * Get the metadata attached to the state class if it exists.
 *
 * @ignore
 */
function getStoreMetadata$1(target) {
  return target[META_KEY];
}
/**
 * Ensures metadata is attached to the selector and returns it.
 *
 * @ignore
 */
function ensureSelectorMetadata$1(target) {
  if (!target.hasOwnProperty(SELECTOR_META_KEY)) {
    const defaultMetadata = {
      makeRootSelector: null,
      originalFn: null,
      containerClass: null,
      selectorName: null,
      getSelectorOptions: () => ({})
    };
    Object.defineProperty(target, SELECTOR_META_KEY, {
      value: defaultMetadata
    });
  }
  return getSelectorMetadata$1(target);
}
/**
 * Get the metadata attached to the selector if it exists.
 *
 * @ignore
 */
function getSelectorMetadata$1(target) {
  return target[SELECTOR_META_KEY];
}
/**
 * Get a deeply nested value. Example:
 *
 *    getValue({ foo: bar: [] }, 'foo.bar') //=> []
 *
 * Note: This is not as fast as the `fastPropGetter` but is strict Content Security Policy compliant.
 * See perf hit: https://jsperf.com/fast-value-getter-given-path/1
 *
 * @ignore
 */
function compliantPropGetter(paths) {
  const copyOfPaths = paths.slice();
  return obj => copyOfPaths.reduce((acc, part) => acc && acc[part], obj);
}
/**
 * The generated function is faster than:
 * - pluck (Observable operator)
 * - memoize
 *
 * @ignore
 */
function fastPropGetter(paths) {
  const segments = paths;
  let seg = 'store.' + segments[0];
  let i = 0;
  const l = segments.length;
  let expr = seg;
  while (++i < l) {
    expr = expr + ' && ' + (seg = seg + '.' + segments[i]);
  }
  const fn = new Function('store', 'return ' + expr + ';');
  return fn;
}
/**
 * Get a deeply nested value. Example:
 *
 *    getValue({ foo: bar: [] }, 'foo.bar') //=> []
 *
 * @ignore
 */
function propGetter(paths, config) {
  if (config && config.compatibility && config.compatibility.strictContentSecurityPolicy) {
    return compliantPropGetter(paths);
  } else {
    return fastPropGetter(paths);
  }
}
/**
 * Given an array of states, it will return a object graph. Example:
 *    const states = [
 *      Cart,
 *      CartSaved,
 *      CartSavedItems
 *    ]
 *
 * would return:
 *
 *  const graph = {
 *    cart: ['saved'],
 *    saved: ['items'],
 *    items: []
 *  };
 *
 * @ignore
 */
function buildGraph(stateClasses) {
  const findName = stateClass => {
    const meta = stateClasses.find(g => g === stateClass);
    // Caretaker note: we have still left the `typeof` condition in order to avoid
    // creating a breaking change for projects that still use the View Engine.
    if ((typeof ngDevMode === 'undefined' || ngDevMode) && !meta) {
      throw new Error(`Child state not found: ${stateClass}. \r\nYou may have forgotten to add states to module`);
    }
    return meta[META_KEY].name;
  };
  return stateClasses.reduce((result, stateClass) => {
    const {
      name,
      children
    } = stateClass[META_KEY];
    result[name] = (children || []).map(findName);
    return result;
  }, {});
}
/**
 * Given a states array, returns object graph
 * returning the name and state metadata. Example:
 *
 *  const graph = {
 *    cart: { metadata }
 *  };
 *
 * @ignore
 */
function nameToState(states) {
  return states.reduce((result, stateClass) => {
    const meta = stateClass[META_KEY];
    result[meta.name] = stateClass;
    return result;
  }, {});
}
/**
 * Given a object relationship graph will return the full path
 * for the child items. Example:
 *
 *  const graph = {
 *    cart: ['saved'],
 *    saved: ['items'],
 *    items: []
 *  };
 *
 * would return:
 *
 *  const r = {
 *    cart: 'cart',
 *    saved: 'cart.saved',
 *    items: 'cart.saved.items'
 *  };
 *
 * @ignore
 */
function findFullParentPath(obj, newObj = {}) {
  const visit = (child, keyToFind) => {
    for (const key in child) {
      if (child.hasOwnProperty(key) && child[key].indexOf(keyToFind) >= 0) {
        const parent = visit(child, key);
        return parent !== null ? `${parent}.${key}` : key;
      }
    }
    return null;
  };
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const parent = visit(obj, key);
      newObj[key] = parent ? `${parent}.${key}` : key;
    }
  }
  return newObj;
}
/**
 * Given a object graph, it will return the items topologically sorted Example:
 *
 *  const graph = {
 *    cart: ['saved'],
 *    saved: ['items'],
 *    items: []
 *  };
 *
 * would return:
 *
 *  const results = [
 *    'items',
 *    'saved',
 *    'cart'
 *  ];
 *
 * @ignore
 */
function topologicalSort(graph) {
  const sorted = [];
  const visited = {};
  const visit = (name, ancestors = []) => {
    if (!Array.isArray(ancestors)) {
      ancestors = [];
    }
    ancestors.push(name);
    visited[name] = true;
    graph[name].forEach(dep => {
      // Caretaker note: we have still left the `typeof` condition in order to avoid
      // creating a breaking change for projects that still use the View Engine.
      if ((typeof ngDevMode === 'undefined' || ngDevMode) && ancestors.indexOf(dep) >= 0) {
        throw new Error(`Circular dependency '${dep}' is required by '${name}': ${ancestors.join(' -> ')}`);
      }
      if (visited[dep]) {
        return;
      }
      visit(dep, ancestors.slice(0));
    });
    if (sorted.indexOf(name) < 0) {
      sorted.push(name);
    }
  };
  Object.keys(graph).forEach(k => visit(k));
  return sorted.reverse();
}
/**
 * Returns if the parameter is a object or not.
 *
 * @ignore
 */
function isObject(obj) {
  return typeof obj === 'object' && obj !== null || typeof obj === 'function';
}

/**
 * RxJS operator for selecting out specific actions.
 *
 * This will grab actions that have just been dispatched as well as actions that have completed
 */
function ofAction(...allowedTypes) {
  return ofActionOperator(allowedTypes);
}
/**
 * RxJS operator for selecting out specific actions.
 *
 * This will ONLY grab actions that have just been dispatched
 */
function ofActionDispatched(...allowedTypes) {
  return ofActionOperator(allowedTypes, ["DISPATCHED" /* Dispatched */]);
}
/**
 * RxJS operator for selecting out specific actions.
 *
 * This will ONLY grab actions that have just been successfully completed
 */
function ofActionSuccessful(...allowedTypes) {
  return ofActionOperator(allowedTypes, ["SUCCESSFUL" /* Successful */]);
}
/**
 * RxJS operator for selecting out specific actions.
 *
 * This will ONLY grab actions that have just been canceled
 */
function ofActionCanceled(...allowedTypes) {
  return ofActionOperator(allowedTypes, ["CANCELED" /* Canceled */]);
}
/**
 * RxJS operator for selecting out specific actions.
 *
 * This will ONLY grab actions that have just been completed
 */
function ofActionCompleted(...allowedTypes) {
  const allowedStatuses = ["SUCCESSFUL" /* Successful */, "CANCELED" /* Canceled */, "ERRORED" /* Errored */];
  return ofActionOperator(allowedTypes, allowedStatuses, mapActionResult);
}
/**
 * RxJS operator for selecting out specific actions.
 *
 * This will ONLY grab actions that have just thrown an error
 */
function ofActionErrored(...allowedTypes) {
  return ofActionOperator(allowedTypes, ["ERRORED" /* Errored */]);
}
function ofActionOperator(allowedTypes, statuses,
// This actually could've been `OperatorFunction<ActionContext, ActionCompletion | any>`,
// since it maps either to `ctx.action` OR to `ActionCompletion`. But `ActionCompleteion | any`
// defaults to `any`, thus there is no sense from union type.
mapOperator = mapAction) {
  const allowedMap = createAllowedActionTypesMap(allowedTypes);
  const allowedStatusMap = statuses && createAllowedStatusesMap(statuses);
  return function (o) {
    return o.pipe(filterStatus(allowedMap, allowedStatusMap), mapOperator());
  };
}
function filterStatus(allowedTypes, allowedStatuses) {
  return filter(ctx => {
    const actionType = getActionTypeFromInstance(ctx.action);
    const typeMatch = allowedTypes[actionType];
    const statusMatch = allowedStatuses ? allowedStatuses[ctx.status] : true;
    return typeMatch && statusMatch;
  });
}
function mapActionResult() {
  return map(({
    action,
    status,
    error
  }) => {
    return {
      action,
      result: {
        successful: "SUCCESSFUL" /* Successful */ === status,
        canceled: "CANCELED" /* Canceled */ === status,
        error
      }
    };
  });
}
function mapAction() {
  return map(ctx => ctx.action);
}
function createAllowedActionTypesMap(types) {
  return types.reduce((filterMap, klass) => {
    filterMap[getActionTypeFromInstance(klass)] = true;
    return filterMap;
  }, {});
}
function createAllowedStatusesMap(statuses) {
  return statuses.reduce((filterMap, status) => {
    filterMap[status] = true;
    return filterMap;
  }, {});
}

/**
 * Returns operator that will run
 * `subscribe` outside of the ngxs execution context
 */
function leaveNgxs(ngxsExecutionStrategy) {
  return source => {
    return new Observable(sink => {
      return source.subscribe({
        next(value) {
          ngxsExecutionStrategy.leave(() => sink.next(value));
        },
        error(error) {
          ngxsExecutionStrategy.leave(() => sink.error(error));
        },
        complete() {
          ngxsExecutionStrategy.leave(() => sink.complete());
        }
      });
    });
  };
}
class InternalNgxsExecutionStrategy {
  constructor(_executionStrategy) {
    this._executionStrategy = _executionStrategy;
  }
  enter(func) {
    return this._executionStrategy.enter(func);
  }
  leave(func) {
    return this._executionStrategy.leave(func);
  }
}
/** @nocollapse */
InternalNgxsExecutionStrategy.ɵfac = function InternalNgxsExecutionStrategy_Factory(t) {
  return new (t || InternalNgxsExecutionStrategy)(i0.ɵɵinject(NGXS_EXECUTION_STRATEGY));
};
/** @nocollapse */
InternalNgxsExecutionStrategy.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: InternalNgxsExecutionStrategy,
  factory: InternalNgxsExecutionStrategy.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalNgxsExecutionStrategy, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [NGXS_EXECUTION_STRATEGY]
      }]
    }];
  }, null);
})();

/**
 * This wraps the provided function, and will enforce the following:
 * - The calls will execute in the order that they are made
 * - A call will only be initiated when the previous call has completed
 * - If there is a call currently executing then the new call will be added
 *   to the queue and the function will return immediately
 *
 * NOTE: The following assumptions about the operation must hold true:
 * - The operation is synchronous in nature
 * - If any asynchronous side effects of the call exist, it should not
 *   have any bearing on the correctness of the next call in the queue
 * - The operation has a void return
 * - The caller should not assume that the call has completed upon
 *   return of the function
 * - The caller can assume that all the queued calls will complete
 *   within the current microtask
 * - The only way that a call will encounter another call in the queue
 *   would be if the call at the front of the queue initiated this call
 *   as part of its synchronous execution
 */
function orderedQueueOperation(operation) {
  const callsQueue = [];
  let busyPushingNext = false;
  return function callOperation(...args) {
    if (busyPushingNext) {
      callsQueue.unshift(args);
      return;
    }
    busyPushingNext = true;
    operation(...args);
    while (callsQueue.length > 0) {
      const nextCallArgs = callsQueue.pop();
      nextCallArgs && operation(...nextCallArgs);
    }
    busyPushingNext = false;
  };
}
/**
 * Custom Subject that ensures that subscribers are notified of values in the order that they arrived.
 * A standard Subject does not have this guarantee.
 * For example, given the following code:
 * ```typescript
 *   const subject = new Subject<string>();
     subject.subscribe(value => {
       if (value === 'start') subject.next('end');
     });
     subject.subscribe(value => { });
     subject.next('start');
 * ```
 * When `subject` is a standard `Subject<T>` the second subscriber would recieve `end` and then `start`.
 * When `subject` is a `OrderedSubject<T>` the second subscriber would recieve `start` and then `end`.
 */
class OrderedSubject extends Subject {
  constructor() {
    super(...arguments);
    this._orderedNext = orderedQueueOperation(value => super.next(value));
  }
  next(value) {
    this._orderedNext(value);
  }
}
/**
 * Custom BehaviorSubject that ensures that subscribers are notified of values in the order that they arrived.
 * A standard BehaviorSubject does not have this guarantee.
 * For example, given the following code:
 * ```typescript
 *   const subject = new BehaviorSubject<string>();
     subject.subscribe(value => {
       if (value === 'start') subject.next('end');
     });
     subject.subscribe(value => { });
     subject.next('start');
 * ```
 * When `subject` is a standard `BehaviorSubject<T>` the second subscriber would recieve `end` and then `start`.
 * When `subject` is a `OrderedBehaviorSubject<T>` the second subscriber would recieve `start` and then `end`.
 */
class OrderedBehaviorSubject extends BehaviorSubject {
  constructor(value) {
    super(value);
    this._orderedNext = orderedQueueOperation(value => super.next(value));
    this._currentValue = value;
  }
  getValue() {
    return this._currentValue;
  }
  next(value) {
    this._currentValue = value;
    this._orderedNext(value);
  }
}

/**
 * Internal Action stream that is emitted anytime an action is dispatched.
 */
class InternalActions extends OrderedSubject {
  ngOnDestroy() {
    this.complete();
  }
}
/** @nocollapse */
InternalActions.ɵfac = /* @__PURE__ */(() => {
  let ɵInternalActions_BaseFactory;
  return function InternalActions_Factory(t) {
    return (ɵInternalActions_BaseFactory || (ɵInternalActions_BaseFactory = i0.ɵɵgetInheritedFactory(InternalActions)))(t || InternalActions);
  };
})();
/** @nocollapse */
InternalActions.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: InternalActions,
  factory: InternalActions.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalActions, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
/**
 * Action stream that is emitted anytime an action is dispatched.
 *
 * You can listen to this in services to react without stores.
 */
class Actions extends Observable {
  constructor(internalActions$, internalExecutionStrategy) {
    const sharedInternalActions$ = internalActions$.pipe(leaveNgxs(internalExecutionStrategy),
    // The `InternalActions` subject emits outside of the Angular zone.
    // We have to re-enter the Angular zone for any incoming consumer.
    // The `share()` operator reduces the number of change detections.
    // This would call leave only once for any stream emission across all active subscribers.
    share());
    super(observer => {
      const childSubscription = sharedInternalActions$.subscribe({
        next: ctx => observer.next(ctx),
        error: error => observer.error(error),
        complete: () => observer.complete()
      });
      observer.add(childSubscription);
    });
  }
}
/** @nocollapse */
Actions.ɵfac = function Actions_Factory(t) {
  return new (t || Actions)(i0.ɵɵinject(InternalActions), i0.ɵɵinject(InternalNgxsExecutionStrategy));
};
/** @nocollapse */
Actions.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: Actions,
  factory: Actions.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Actions, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: InternalActions
    }, {
      type: InternalNgxsExecutionStrategy
    }];
  }, null);
})();

/**
 * Composes a array of functions from left to right. Example:
 *
 *      compose([fn, final])(state, action);
 *
 * then the funcs have a signature like:
 *
 *      function fn (state, action, next) {
 *          console.log('here', state, action, next);
 *          return next(state, action);
 *      }
 *
 *      function final (state, action) {
 *          console.log('here', state, action);
 *          return state;
 *      }
 *
 * the last function should not call `next`.
 *
 * @ignore
 */
const compose = funcs => (...args) => {
  const curr = funcs.shift();
  return curr(...args, (...nextArgs) => compose(funcs)(...nextArgs));
};

/**
 * This operator is used for piping the observable result
 * from the `dispatch()`. It has a "smart" error handling
 * strategy that allows us to decide whether we propagate
 * errors to Angular's `ErrorHandler` or enable users to
 * handle them manually. We consider following cases:
 * 1) `store.dispatch()` (no subscribe) -> call `handleError()`
 * 2) `store.dispatch().subscribe()` (no error callback) -> call `handleError()`
 * 3) `store.dispatch().subscribe({ error: ... })` -> don't call `handleError()`
 * 4) `toPromise()` without `catch` -> do `handleError()`
 * 5) `toPromise()` with `catch` -> don't `handleError()`
 */
function ngxsErrorHandler(internalErrorReporter, ngxsExecutionStrategy) {
  return source => {
    let subscribed = false;
    source.subscribe({
      error: error => {
        // Do not trigger change detection for a microtask. This depends on the execution
        // strategy being used, but the default `DispatchOutsideZoneNgxsExecutionStrategy`
        // leaves the Angular zone.
        ngxsExecutionStrategy.enter(() => Promise.resolve().then(() => {
          if (!subscribed) {
            ngxsExecutionStrategy.leave(() => internalErrorReporter.reportErrorSafely(error));
          }
        }));
      }
    });
    return new Observable(subscriber => {
      subscribed = true;
      return source.pipe(leaveNgxs(ngxsExecutionStrategy)).subscribe(subscriber);
    });
  };
}
class InternalErrorReporter {
  constructor(_injector) {
    this._injector = _injector;
    /** Will be set lazily to be backward compatible. */
    this._errorHandler = null;
  }
  reportErrorSafely(error) {
    if (this._errorHandler === null) {
      this._errorHandler = this._injector.get(ErrorHandler);
    }
    // The `try-catch` is used to avoid handling the error twice. Suppose we call
    // `handleError` which re-throws the error internally. The re-thrown error will
    // be caught by zone.js which will then get to the `zone.onError.emit()` and the
    // `onError` subscriber will call `handleError` again.
    try {
      this._errorHandler.handleError(error);
    } catch (_a) {}
  }
}
/** @nocollapse */
InternalErrorReporter.ɵfac = function InternalErrorReporter_Factory(t) {
  return new (t || InternalErrorReporter)(i0.ɵɵinject(i0.Injector));
};
/** @nocollapse */
InternalErrorReporter.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: InternalErrorReporter,
  factory: InternalErrorReporter.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalErrorReporter, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.Injector
    }];
  }, null);
})();

/**
 * BehaviorSubject of the entire state.
 * @ignore
 */
class StateStream extends OrderedBehaviorSubject {
  constructor() {
    super({});
  }
  ngOnDestroy() {
    // The `StateStream` should never emit values once the root view is removed, e.g. when the `NgModuleRef.destroy()` is called.
    // This will eliminate memory leaks in server-side rendered apps where the `StateStream` is created per each HTTP request, users
    // might forget to unsubscribe from `store.select` or `store.subscribe`, thus this will lead to huge memory leaks in SSR apps.
    this.complete();
  }
}
/** @nocollapse */
StateStream.ɵfac = function StateStream_Factory(t) {
  return new (t || StateStream)();
};
/** @nocollapse */
StateStream.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: StateStream,
  factory: StateStream.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StateStream, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();
class PluginManager {
  constructor(_parentManager, _pluginHandlers) {
    this._parentManager = _parentManager;
    this._pluginHandlers = _pluginHandlers;
    this.plugins = [];
    this.registerHandlers();
  }
  get rootPlugins() {
    return this._parentManager && this._parentManager.plugins || this.plugins;
  }
  registerHandlers() {
    const pluginHandlers = this.getPluginHandlers();
    this.rootPlugins.push(...pluginHandlers);
  }
  getPluginHandlers() {
    const handlers = this._pluginHandlers || [];
    return handlers.map(plugin => plugin.handle ? plugin.handle.bind(plugin) : plugin);
  }
}
/** @nocollapse */
PluginManager.ɵfac = function PluginManager_Factory(t) {
  return new (t || PluginManager)(i0.ɵɵinject(PluginManager, 12), i0.ɵɵinject(NGXS_PLUGINS, 8));
};
/** @nocollapse */
PluginManager.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: PluginManager,
  factory: PluginManager.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PluginManager, [{
    type: Injectable
  }], function () {
    return [{
      type: PluginManager,
      decorators: [{
        type: Optional
      }, {
        type: SkipSelf
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [NGXS_PLUGINS]
      }, {
        type: Optional
      }]
    }];
  }, null);
})();

/**
 * Internal Action result stream that is emitted when an action is completed.
 * This is used as a method of returning the action result to the dispatcher
 * for the observable returned by the dispatch(...) call.
 * The dispatcher then asynchronously pushes the result from this stream onto the main action stream as a result.
 */
class InternalDispatchedActionResults extends Subject {}
/** @nocollapse */
InternalDispatchedActionResults.ɵfac = /* @__PURE__ */(() => {
  let ɵInternalDispatchedActionResults_BaseFactory;
  return function InternalDispatchedActionResults_Factory(t) {
    return (ɵInternalDispatchedActionResults_BaseFactory || (ɵInternalDispatchedActionResults_BaseFactory = i0.ɵɵgetInheritedFactory(InternalDispatchedActionResults)))(t || InternalDispatchedActionResults);
  };
})();
/** @nocollapse */
InternalDispatchedActionResults.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: InternalDispatchedActionResults,
  factory: InternalDispatchedActionResults.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalDispatchedActionResults, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class InternalDispatcher {
  constructor(_actions, _actionResults, _pluginManager, _stateStream, _ngxsExecutionStrategy, _internalErrorReporter) {
    this._actions = _actions;
    this._actionResults = _actionResults;
    this._pluginManager = _pluginManager;
    this._stateStream = _stateStream;
    this._ngxsExecutionStrategy = _ngxsExecutionStrategy;
    this._internalErrorReporter = _internalErrorReporter;
  }
  /**
   * Dispatches event(s).
   */
  dispatch(actionOrActions) {
    const result = this._ngxsExecutionStrategy.enter(() => this.dispatchByEvents(actionOrActions));
    return result.pipe(ngxsErrorHandler(this._internalErrorReporter, this._ngxsExecutionStrategy));
  }
  dispatchByEvents(actionOrActions) {
    if (Array.isArray(actionOrActions)) {
      if (actionOrActions.length === 0) return of(this._stateStream.getValue());
      return forkJoin(actionOrActions.map(action => this.dispatchSingle(action)));
    } else {
      return this.dispatchSingle(actionOrActions);
    }
  }
  dispatchSingle(action) {
    if (typeof ngDevMode === 'undefined' || ngDevMode) {
      const type = getActionTypeFromInstance(action);
      if (!type) {
        const error = new Error(`This action doesn't have a type property: ${action.constructor.name}`);
        return throwError(error);
      }
    }
    const prevState = this._stateStream.getValue();
    const plugins = this._pluginManager.plugins;
    return compose([...plugins, (nextState, nextAction) => {
      if (nextState !== prevState) {
        this._stateStream.next(nextState);
      }
      const actionResult$ = this.getActionResultStream(nextAction);
      actionResult$.subscribe(ctx => this._actions.next(ctx));
      this._actions.next({
        action: nextAction,
        status: "DISPATCHED" /* Dispatched */
      });
      return this.createDispatchObservable(actionResult$);
    }])(prevState, action).pipe(shareReplay());
  }
  getActionResultStream(action) {
    return this._actionResults.pipe(filter(ctx => ctx.action === action && ctx.status !== "DISPATCHED" /* Dispatched */), take(1), shareReplay());
  }
  createDispatchObservable(actionResult$) {
    return actionResult$.pipe(exhaustMap(ctx => {
      switch (ctx.status) {
        case "SUCCESSFUL" /* Successful */:
          return of(this._stateStream.getValue());
        case "ERRORED" /* Errored */:
          return throwError(ctx.error);
        default:
          return EMPTY;
      }
    })).pipe(shareReplay());
  }
}
/** @nocollapse */
InternalDispatcher.ɵfac = function InternalDispatcher_Factory(t) {
  return new (t || InternalDispatcher)(i0.ɵɵinject(InternalActions), i0.ɵɵinject(InternalDispatchedActionResults), i0.ɵɵinject(PluginManager), i0.ɵɵinject(StateStream), i0.ɵɵinject(InternalNgxsExecutionStrategy), i0.ɵɵinject(InternalErrorReporter));
};
/** @nocollapse */
InternalDispatcher.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: InternalDispatcher,
  factory: InternalDispatcher.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalDispatcher, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: InternalActions
    }, {
      type: InternalDispatchedActionResults
    }, {
      type: PluginManager
    }, {
      type: StateStream
    }, {
      type: InternalNgxsExecutionStrategy
    }, {
      type: InternalErrorReporter
    }];
  }, null);
})();

/**
 * Object freeze code
 * https://github.com/jsdf/deep-freeze
 */
const deepFreeze = o => {
  Object.freeze(o);
  const oIsFunction = typeof o === 'function';
  const hasOwnProp = Object.prototype.hasOwnProperty;
  Object.getOwnPropertyNames(o).forEach(function (prop) {
    if (hasOwnProp.call(o, prop) && (oIsFunction ? prop !== 'caller' && prop !== 'callee' && prop !== 'arguments' : true) && o[prop] !== null && (typeof o[prop] === 'object' || typeof o[prop] === 'function') && !Object.isFrozen(o[prop])) {
      deepFreeze(o[prop]);
    }
  });
  return o;
};

/**
 * @ignore
 */
class InternalStateOperations {
  constructor(_stateStream, _dispatcher, _config) {
    this._stateStream = _stateStream;
    this._dispatcher = _dispatcher;
    this._config = _config;
  }
  /**
   * Returns the root state operators.
   */
  getRootStateOperations() {
    const rootStateOperations = {
      getState: () => this._stateStream.getValue(),
      setState: newState => this._stateStream.next(newState),
      dispatch: actionOrActions => this._dispatcher.dispatch(actionOrActions)
    };
    if (typeof ngDevMode === 'undefined' || ngDevMode) {
      return this._config.developmentMode ? ensureStateAndActionsAreImmutable(rootStateOperations) : rootStateOperations;
    } else {
      return rootStateOperations;
    }
  }
  setStateToTheCurrentWithNew(results) {
    const stateOperations = this.getRootStateOperations();
    // Get our current stream
    const currentState = stateOperations.getState();
    // Set the state to the current + new
    stateOperations.setState(Object.assign(Object.assign({}, currentState), results.defaults));
  }
}
/** @nocollapse */
InternalStateOperations.ɵfac = function InternalStateOperations_Factory(t) {
  return new (t || InternalStateOperations)(i0.ɵɵinject(StateStream), i0.ɵɵinject(InternalDispatcher), i0.ɵɵinject(NgxsConfig));
};
/** @nocollapse */
InternalStateOperations.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: InternalStateOperations,
  factory: InternalStateOperations.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalStateOperations, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: StateStream
    }, {
      type: InternalDispatcher
    }, {
      type: NgxsConfig
    }];
  }, null);
})();
function ensureStateAndActionsAreImmutable(root) {
  return {
    getState: () => root.getState(),
    setState: value => {
      const frozenValue = deepFreeze(value);
      return root.setState(frozenValue);
    },
    dispatch: actions => {
      return root.dispatch(actions);
    }
  };
}
function simplePatch(value) {
  return existingState => {
    if (typeof ngDevMode === 'undefined' || ngDevMode) {
      if (Array.isArray(value)) {
        throwPatchingArrayError();
      } else if (typeof value !== 'object') {
        throwPatchingPrimitiveError();
      }
    }
    const newState = Object.assign({}, existingState);
    for (const key in value) {
      // deep clone for patch compatibility
      newState[key] = value[key];
    }
    return newState;
  };
}

/**
 * State Context factory class
 * @ignore
 */
class StateContextFactory {
  constructor(_internalStateOperations) {
    this._internalStateOperations = _internalStateOperations;
  }
  /**
   * Create the state context
   */
  createStateContext(mappedStore) {
    const root = this._internalStateOperations.getRootStateOperations();
    function getState(currentAppState) {
      return getValue(currentAppState, mappedStore.path);
    }
    function setStateValue(currentAppState, newValue) {
      const newAppState = setValue(currentAppState, mappedStore.path, newValue);
      root.setState(newAppState);
      return newAppState;
      // In doing this refactoring I noticed that there is a 'bug' where the
      // application state is returned instead of this state slice.
      // This has worked this way since the beginning see:
      // https://github.com/ngxs/store/blame/324c667b4b7debd8eb979006c67ca0ae347d88cd/src/state-factory.ts
      // This needs to be fixed, but is a 'breaking' change.
      // I will do this fix in a subsequent PR and we can decide how to handle it.
    }
    function setStateFromOperator(currentAppState, stateOperator) {
      const local = getState(currentAppState);
      const newValue = stateOperator(local);
      return setStateValue(currentAppState, newValue);
    }
    function isStateOperator(value) {
      return typeof value === 'function';
    }
    return {
      getState() {
        const currentAppState = root.getState();
        return getState(currentAppState);
      },
      patchState(val) {
        const currentAppState = root.getState();
        const patchOperator = simplePatch(val);
        return setStateFromOperator(currentAppState, patchOperator);
      },
      setState(val) {
        const currentAppState = root.getState();
        return isStateOperator(val) ? setStateFromOperator(currentAppState, val) : setStateValue(currentAppState, val);
      },
      dispatch(actions) {
        return root.dispatch(actions);
      }
    };
  }
}
/** @nocollapse */
StateContextFactory.ɵfac = function StateContextFactory_Factory(t) {
  return new (t || StateContextFactory)(i0.ɵɵinject(InternalStateOperations));
};
/** @nocollapse */
StateContextFactory.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: StateContextFactory,
  factory: StateContextFactory.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StateContextFactory, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: InternalStateOperations
    }];
  }, null);
})();
class StoreValidators {
  static checkThatStateIsNamedCorrectly(name) {
    if (!name) {
      throwStateNamePropertyError();
    } else if (!this.stateNameRegex.test(name)) {
      throwStateNameError(name);
    }
  }
  static checkThatStateNameIsUnique(stateName, state, statesByName) {
    const existingState = statesByName[stateName];
    if (existingState && existingState !== state) {
      throwStateUniqueError(stateName, state.name, existingState.name);
    }
  }
  static checkThatStateClassesHaveBeenDecorated(stateClasses) {
    stateClasses.forEach(stateClass => {
      if (!getStoreMetadata$1(stateClass)) {
        throwStateDecoratorError(stateClass.name);
      }
    });
  }
}
StoreValidators.stateNameRegex = new RegExp('^[a-zA-Z0-9_]+$');

/**
 * All provided or injected tokens must have `@Injectable` decorator
 * (previously, injected tokens without `@Injectable` were allowed
 * if another decorator was used, e.g. pipes).
 */
function ensureStateClassIsInjectable(stateClass) {
  if (jit_hasInjectableAnnotation(stateClass) || aot_hasNgInjectableDef(stateClass)) {
    return;
  }
  console.warn(getUndecoratedStateInIvyWarningMessage(stateClass.name));
}
function aot_hasNgInjectableDef(stateClass) {
  // `ɵprov` is a static property added by the NGCC compiler. It always exists in
  // AOT mode because this property is added before runtime. If an application is running in
  // JIT mode then this property can be added by the `@Injectable()` decorator. The `@Injectable()`
  // decorator has to go after the `@State()` decorator, thus we prevent users from unwanted DI errors.
  return !!stateClass.ɵprov;
}
function jit_hasInjectableAnnotation(stateClass) {
  // `ɵprov` doesn't exist in JIT mode (for instance when running unit tests with Jest).
  const annotations = stateClass.__annotations__ || [];
  return annotations.some(annotation => (annotation === null || annotation === void 0 ? void 0 : annotation.ngMetadataName) === 'Injectable');
}

/**
 * Init action
 */
class InitState {
  static get type() {
    // NOTE: Not necessary to declare the type in this way in your code. See https://github.com/ngxs/store/pull/644#issuecomment-436003138
    return '@@INIT';
  }
}
/**
 * Update action
 */
class UpdateState {
  constructor(addedStates) {
    this.addedStates = addedStates;
  }
  static get type() {
    // NOTE: Not necessary to declare the type in this way in your code. See https://github.com/ngxs/store/pull/644#issuecomment-436003138
    return '@@UPDATE_STATE';
  }
}
const NGXS_DEVELOPMENT_OPTIONS = new InjectionToken('NGXS_DEVELOPMENT_OPTIONS', {
  providedIn: 'root',
  factory: () => ({
    warnOnUnhandledActions: true
  })
});
class NgxsUnhandledActionsLogger {
  constructor(options) {
    /**
     * These actions should be ignored by default; the user can increase this
     * list in the future via the `ignoreActions` method.
     */
    this._ignoredActions = new Set([InitState.type, UpdateState.type]);
    if (typeof options.warnOnUnhandledActions === 'object') {
      this.ignoreActions(...options.warnOnUnhandledActions.ignore);
    }
  }
  /**
   * Adds actions to the internal list of actions that should be ignored.
   */
  ignoreActions(...actions) {
    for (const action of actions) {
      this._ignoredActions.add(action.type);
    }
  }
  /** @internal */
  warn(action) {
    const actionShouldBeIgnored = Array.from(this._ignoredActions).some(type => type === getActionTypeFromInstance(action));
    if (actionShouldBeIgnored) {
      return;
    }
    action = action.constructor && action.constructor.name !== 'Object' ? action.constructor.name : action.type;
    console.warn(`The ${action} action has been dispatched but hasn't been handled. This may happen if the state with an action handler for this action is not registered.`);
  }
}
/** @nocollapse */
NgxsUnhandledActionsLogger.ɵfac = function NgxsUnhandledActionsLogger_Factory(t) {
  return new (t || NgxsUnhandledActionsLogger)(i0.ɵɵinject(NGXS_DEVELOPMENT_OPTIONS));
};
/** @nocollapse */
NgxsUnhandledActionsLogger.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgxsUnhandledActionsLogger,
  factory: NgxsUnhandledActionsLogger.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsUnhandledActionsLogger, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [NGXS_DEVELOPMENT_OPTIONS]
      }]
    }];
  }, null);
})();
const NG_DEV_MODE = typeof ngDevMode === 'undefined' || ngDevMode;
/**
 * The `StateFactory` class adds root and feature states to the graph.
 * This extracts state names from state classes, checks if they already
 * exist in the global graph, throws errors if their names are invalid, etc.
 * See its constructor, state factories inject state factories that are
 * parent-level providers. This is required to get feature states from the
 * injector on the same level.
 *
 * The `NgxsModule.forFeature(...)` returns `providers: [StateFactory, ...states]`.
 * The `StateFactory` is initialized on the feature level and goes through `...states`
 * to get them from the injector through `injector.get(state)`.
 * @ignore
 */
class StateFactory {
  constructor(_injector, _config, _parentFactory, _actions, _actionResults, _stateContextFactory, _initialState) {
    this._injector = _injector;
    this._config = _config;
    this._parentFactory = _parentFactory;
    this._actions = _actions;
    this._actionResults = _actionResults;
    this._stateContextFactory = _stateContextFactory;
    this._initialState = _initialState;
    this._actionsSubscription = null;
    this._states = [];
    this._statesByName = {};
    this._statePaths = {};
    this.getRuntimeSelectorContext = memoize(() => {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const stateFactory = this;
      function resolveGetter(key) {
        const path = stateFactory.statePaths[key];
        return path ? propGetter(path.split('.'), stateFactory._config) : null;
      }
      const context = this._parentFactory ? this._parentFactory.getRuntimeSelectorContext() : {
        getStateGetter(key) {
          let getter = resolveGetter(key);
          if (getter) {
            return getter;
          }
          return (...args) => {
            // Late loaded getter
            if (!getter) {
              getter = resolveGetter(key);
            }
            return getter ? getter(...args) : undefined;
          };
        },
        getSelectorOptions(localOptions) {
          const globalSelectorOptions = stateFactory._config.selectorOptions;
          return Object.assign(Object.assign({}, globalSelectorOptions), localOptions || {});
        }
      };
      return context;
    });
  }
  get states() {
    return this._parentFactory ? this._parentFactory.states : this._states;
  }
  get statesByName() {
    return this._parentFactory ? this._parentFactory.statesByName : this._statesByName;
  }
  get statePaths() {
    return this._parentFactory ? this._parentFactory.statePaths : this._statePaths;
  }
  static cloneDefaults(defaults) {
    let value = {};
    if (Array.isArray(defaults)) {
      value = defaults.slice();
    } else if (isObject(defaults)) {
      value = Object.assign({}, defaults);
    } else if (defaults === undefined) {
      value = {};
    } else {
      value = defaults;
    }
    return value;
  }
  ngOnDestroy() {
    var _a;
    (_a = this._actionsSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
  }
  /**
   * Add a new state to the global defs.
   */
  add(stateClasses) {
    if (NG_DEV_MODE) {
      StoreValidators.checkThatStateClassesHaveBeenDecorated(stateClasses);
    }
    const {
      newStates
    } = this.addToStatesMap(stateClasses);
    if (!newStates.length) return [];
    const stateGraph = buildGraph(newStates);
    const sortedStates = topologicalSort(stateGraph);
    const paths = findFullParentPath(stateGraph);
    const nameGraph = nameToState(newStates);
    const bootstrappedStores = [];
    for (const name of sortedStates) {
      const stateClass = nameGraph[name];
      const path = paths[name];
      const meta = stateClass[META_KEY];
      this.addRuntimeInfoToMeta(meta, path);
      // Note: previously we called `ensureStateClassIsInjectable` within the
      // `State` decorator. This check is moved here because the `ɵprov` property
      // will not exist on the class in JIT mode (because it's set asynchronously
      // during JIT compilation through `Object.defineProperty`).
      if (typeof ngDevMode === 'undefined' || ngDevMode) {
        ensureStateClassIsInjectable(stateClass);
      }
      const stateMap = {
        name,
        path,
        isInitialised: false,
        actions: meta.actions,
        instance: this._injector.get(stateClass),
        defaults: StateFactory.cloneDefaults(meta.defaults)
      };
      // ensure our store hasn't already been added
      // but don't throw since it could be lazy
      // loaded from different paths
      if (!this.hasBeenMountedAndBootstrapped(name, path)) {
        bootstrappedStores.push(stateMap);
      }
      this.states.push(stateMap);
    }
    return bootstrappedStores;
  }
  /**
   * Add a set of states to the store and return the defaults
   */
  addAndReturnDefaults(stateClasses) {
    const classes = stateClasses || [];
    const mappedStores = this.add(classes);
    const defaults = mappedStores.reduce((result, mappedStore) => setValue(result, mappedStore.path, mappedStore.defaults), {});
    return {
      defaults,
      states: mappedStores
    };
  }
  connectActionHandlers() {
    // Note: We have to connect actions only once when the `StateFactory`
    //       is being created for the first time. This checks if we're in
    //       a child state factory and the parent state factory already exists.
    if (this._parentFactory || this._actionsSubscription !== null) {
      return;
    }
    const dispatched$ = new Subject();
    this._actionsSubscription = this._actions.pipe(filter(ctx => ctx.status === "DISPATCHED" /* Dispatched */), mergeMap(ctx => {
      dispatched$.next(ctx);
      const action = ctx.action;
      return this.invokeActions(dispatched$, action).pipe(map(() => ({
        action,
        status: "SUCCESSFUL" /* Successful */
      })), defaultIfEmpty({
        action,
        status: "CANCELED" /* Canceled */
      }), catchError(error => of({
        action,
        status: "ERRORED" /* Errored */,
        error
      })));
    })).subscribe(ctx => this._actionResults.next(ctx));
  }
  /**
   * Invoke actions on the states.
   */
  invokeActions(dispatched$, action) {
    const type = getActionTypeFromInstance(action);
    const results = [];
    // Determines whether the dispatched action has been handled, this is assigned
    // to `true` within the below `for` loop if any `actionMetas` has been found.
    let actionHasBeenHandled = false;
    for (const metadata of this.states) {
      const actionMetas = metadata.actions[type];
      if (actionMetas) {
        for (const actionMeta of actionMetas) {
          const stateContext = this._stateContextFactory.createStateContext(metadata);
          try {
            let result = metadata.instance[actionMeta.fn](stateContext, action);
            if (result instanceof Promise) {
              result = from(result);
            }
            if (isObservable(result)) {
              // If this observable has been completed w/o emitting
              // any value then we wouldn't want to complete the whole chain
              // of actions. Since if any observable completes then
              // action will be canceled.
              // For instance if any action handler would've had such statement:
              // `handler(ctx) { return EMPTY; }`
              // then the action will be canceled.
              // See https://github.com/ngxs/store/issues/1568
              result = result.pipe(mergeMap(value => {
                if (value instanceof Promise) {
                  return from(value);
                }
                if (isObservable(value)) {
                  return value;
                }
                return of(value);
              }), defaultIfEmpty({}));
              if (actionMeta.options.cancelUncompleted) {
                // todo: ofActionDispatched should be used with action class
                result = result.pipe(takeUntil(dispatched$.pipe(ofActionDispatched(action))));
              }
            } else {
              result = of({}).pipe(shareReplay());
            }
            results.push(result);
          } catch (e) {
            results.push(throwError(e));
          }
          actionHasBeenHandled = true;
        }
      }
    }
    // The `NgxsUnhandledActionsLogger` is a tree-shakable class which functions
    // only during development.
    if (NG_DEV_MODE && !actionHasBeenHandled) {
      const unhandledActionsLogger = this._injector.get(NgxsUnhandledActionsLogger, null);
      // The `NgxsUnhandledActionsLogger` will not be resolved by the injector if the
      // `NgxsDevelopmentModule` is not provided. It's enough to check whether the `injector.get`
      // didn't return `null` so we may ensure the module has been imported.
      if (unhandledActionsLogger) {
        unhandledActionsLogger.warn(action);
      }
    }
    if (!results.length) {
      results.push(of({}));
    }
    return forkJoin(results);
  }
  addToStatesMap(stateClasses) {
    const newStates = [];
    const statesMap = this.statesByName;
    for (const stateClass of stateClasses) {
      const stateName = getStoreMetadata$1(stateClass).name;
      if (NG_DEV_MODE) {
        StoreValidators.checkThatStateNameIsUnique(stateName, stateClass, statesMap);
      }
      const unmountedState = !statesMap[stateName];
      if (unmountedState) {
        newStates.push(stateClass);
        statesMap[stateName] = stateClass;
      }
    }
    return {
      newStates
    };
  }
  addRuntimeInfoToMeta(meta, path) {
    this.statePaths[meta.name] = path;
    // TODO: v4 - we plan to get rid of the path property because it is non-deterministic
    // we can do this when we get rid of the incorrectly exposed getStoreMetadata
    // We will need to come up with an alternative in v4 because this is used by many plugins
    meta.path = path;
  }
  hasBeenMountedAndBootstrapped(name, path) {
    const valueIsBootstrappedInInitialState = getValue(this._initialState, path) !== undefined;
    // This checks whether a state has been already added to the global graph and
    // its lifecycle is in 'bootstrapped' state.
    return this.statesByName[name] && valueIsBootstrappedInInitialState;
  }
}
/** @nocollapse */
StateFactory.ɵfac = function StateFactory_Factory(t) {
  return new (t || StateFactory)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(NgxsConfig), i0.ɵɵinject(StateFactory, 12), i0.ɵɵinject(InternalActions), i0.ɵɵinject(InternalDispatchedActionResults), i0.ɵɵinject(StateContextFactory), i0.ɵɵinject(INITIAL_STATE_TOKEN, 8));
};
/** @nocollapse */
StateFactory.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: StateFactory,
  factory: StateFactory.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StateFactory, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.Injector
    }, {
      type: NgxsConfig
    }, {
      type: StateFactory,
      decorators: [{
        type: Optional
      }, {
        type: SkipSelf
      }]
    }, {
      type: InternalActions
    }, {
      type: InternalDispatchedActionResults
    }, {
      type: StateContextFactory
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [INITIAL_STATE_TOKEN]
      }]
    }];
  }, null);
})();
function createRootSelectorFactory(selectorMetaData, selectors, memoizedSelectorFn) {
  return context => {
    const {
      argumentSelectorFunctions,
      selectorOptions
    } = getRuntimeSelectorInfo(context, selectorMetaData, selectors);
    return function selectFromRoot(rootState) {
      // Determine arguments from the app state using the selectors
      const results = argumentSelectorFunctions.map(argFn => argFn(rootState));
      // if the lambda tries to access a something on the
      // state that doesn't exist, it will throw a TypeError.
      // since this is quite usual behaviour, we simply return undefined if so.
      try {
        return memoizedSelectorFn(...results);
      } catch (ex) {
        if (ex instanceof TypeError && selectorOptions.suppressErrors) {
          return undefined;
        }
        throw ex;
      }
    };
  };
}
function createMemoizedSelectorFn(originalFn, creationMetadata) {
  const containerClass = creationMetadata && creationMetadata.containerClass;
  const wrappedFn = function wrappedSelectorFn(...args) {
    const returnValue = originalFn.apply(containerClass, args);
    if (returnValue instanceof Function) {
      const innerMemoizedFn = memoize.apply(null, [returnValue]);
      return innerMemoizedFn;
    }
    return returnValue;
  };
  const memoizedFn = memoize(wrappedFn);
  Object.setPrototypeOf(memoizedFn, originalFn);
  return memoizedFn;
}
function getRuntimeSelectorInfo(context, selectorMetaData, selectors = []) {
  const localSelectorOptions = selectorMetaData.getSelectorOptions();
  const selectorOptions = context.getSelectorOptions(localSelectorOptions);
  const selectorsToApply = getSelectorsToApply(selectors, selectorOptions, selectorMetaData.containerClass);
  const argumentSelectorFunctions = selectorsToApply.map(selector => {
    const factory = getRootSelectorFactory(selector);
    return factory(context);
  });
  return {
    selectorOptions,
    argumentSelectorFunctions
  };
}
function getSelectorsToApply(selectors = [], selectorOptions, containerClass) {
  const selectorsToApply = [];
  const canInjectContainerState = selectors.length === 0 || selectorOptions.injectContainerState;
  if (containerClass && canInjectContainerState) {
    // If we are on a state class, add it as the first selector parameter
    const metadata = getStoreMetadata$1(containerClass);
    if (metadata) {
      selectorsToApply.push(containerClass);
    }
  }
  if (selectors) {
    selectorsToApply.push(...selectors);
  }
  return selectorsToApply;
}
/**
 * This function gets the factory function to create the selector to get the selected slice from the app state
 * @ignore
 */
function getRootSelectorFactory(selector) {
  const metadata = getSelectorMetadata$1(selector) || getStoreMetadata$1(selector);
  return metadata && metadata.makeRootSelector || (() => selector);
}

// tslint:disable:unified-signatures
class Store {
  constructor(_stateStream, _internalStateOperations, _config, _internalExecutionStrategy, _stateFactory, initialStateValue) {
    this._stateStream = _stateStream;
    this._internalStateOperations = _internalStateOperations;
    this._config = _config;
    this._internalExecutionStrategy = _internalExecutionStrategy;
    this._stateFactory = _stateFactory;
    /**
     * This is a derived state stream that leaves NGXS execution strategy to emit state changes within the Angular zone,
     * because state is being changed actually within the `<root>` zone, see `InternalDispatcher#dispatchSingle`.
     * All selects would use this stream, and it would call leave only once for any state change across all active selectors.
     */
    this._selectableStateStream = this._stateStream.pipe(leaveNgxs(this._internalExecutionStrategy), shareReplay({
      bufferSize: 1,
      refCount: true
    }));
    this.initStateStream(initialStateValue);
  }
  /**
   * Dispatches event(s).
   */
  dispatch(actionOrActions) {
    return this._internalStateOperations.getRootStateOperations().dispatch(actionOrActions);
  }
  select(selector) {
    const selectorFn = this.getStoreBoundSelectorFn(selector);
    return this._selectableStateStream.pipe(map(selectorFn), catchError(err => {
      // if error is TypeError we swallow it to prevent usual errors with property access
      const {
        suppressErrors
      } = this._config.selectorOptions;
      if (err instanceof TypeError && suppressErrors) {
        return of(undefined);
      }
      // rethrow other errors
      return throwError(err);
    }), distinctUntilChanged(), leaveNgxs(this._internalExecutionStrategy));
  }
  selectOnce(selector) {
    return this.select(selector).pipe(take(1));
  }
  selectSnapshot(selector) {
    const selectorFn = this.getStoreBoundSelectorFn(selector);
    return selectorFn(this._stateStream.getValue());
  }
  /**
   * Allow the user to subscribe to the root of the state
   */
  subscribe(fn) {
    return this._selectableStateStream.pipe(leaveNgxs(this._internalExecutionStrategy)).subscribe(fn);
  }
  /**
   * Return the raw value of the state.
   */
  snapshot() {
    return this._internalStateOperations.getRootStateOperations().getState();
  }
  /**
   * Reset the state to a specific point in time. This method is useful
   * for plugin's who need to modify the state directly or unit testing.
   */
  reset(state) {
    return this._internalStateOperations.getRootStateOperations().setState(state);
  }
  getStoreBoundSelectorFn(selector) {
    const makeSelectorFn = getRootSelectorFactory(selector);
    const runtimeContext = this._stateFactory.getRuntimeSelectorContext();
    return makeSelectorFn(runtimeContext);
  }
  initStateStream(initialStateValue) {
    const value = this._stateStream.value;
    const storeIsEmpty = !value || Object.keys(value).length === 0;
    if (storeIsEmpty) {
      const defaultStateNotEmpty = Object.keys(this._config.defaultsState).length > 0;
      const storeValues = defaultStateNotEmpty ? Object.assign(Object.assign({}, this._config.defaultsState), initialStateValue) : initialStateValue;
      this._stateStream.next(storeValues);
    }
  }
}
/** @nocollapse */
Store.ɵfac = function Store_Factory(t) {
  return new (t || Store)(i0.ɵɵinject(StateStream), i0.ɵɵinject(InternalStateOperations), i0.ɵɵinject(NgxsConfig), i0.ɵɵinject(InternalNgxsExecutionStrategy), i0.ɵɵinject(StateFactory), i0.ɵɵinject(INITIAL_STATE_TOKEN, 8));
};
/** @nocollapse */
Store.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: Store,
  factory: Store.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Store, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: StateStream
    }, {
      type: InternalStateOperations
    }, {
      type: NgxsConfig
    }, {
      type: InternalNgxsExecutionStrategy
    }, {
      type: StateFactory
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [INITIAL_STATE_TOKEN]
      }]
    }];
  }, null);
})();

/**
 * Allows the select decorator to get access to the DI store, this is used internally
 * in `@Select` decorator.
 */
class SelectFactory {
  constructor(store, config) {
    SelectFactory.store = store;
    SelectFactory.config = config;
  }
  ngOnDestroy() {
    SelectFactory.store = null;
    SelectFactory.config = null;
  }
}
SelectFactory.store = null;
SelectFactory.config = null;
/** @nocollapse */
SelectFactory.ɵfac = function SelectFactory_Factory(t) {
  return new (t || SelectFactory)(i0.ɵɵinject(Store), i0.ɵɵinject(NgxsConfig));
};
/** @nocollapse */
SelectFactory.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: SelectFactory,
  factory: SelectFactory.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SelectFactory, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: Store
    }, {
      type: NgxsConfig
    }];
  }, null);
})();
class LifecycleStateManager {
  constructor(_store, _internalErrorReporter, _internalStateOperations, _stateContextFactory, _bootstrapper) {
    this._store = _store;
    this._internalErrorReporter = _internalErrorReporter;
    this._internalStateOperations = _internalStateOperations;
    this._stateContextFactory = _stateContextFactory;
    this._bootstrapper = _bootstrapper;
    this._destroy$ = new Subject();
  }
  ngOnDestroy() {
    this._destroy$.next();
  }
  ngxsBootstrap(action, results) {
    this._internalStateOperations.getRootStateOperations().dispatch(action).pipe(filter(() => !!results), tap(() => this._invokeInitOnStates(results.states)), mergeMap(() => this._bootstrapper.appBootstrapped$), filter(appBootstrapped => !!appBootstrapped), catchError(error => {
      // The `SafeSubscriber` (which is used by most RxJS operators) re-throws
      // errors asynchronously (`setTimeout(() => { throw error })`). This might
      // break existing user's code or unit tests. We catch the error manually to
      // be backward compatible with the old behavior.
      this._internalErrorReporter.reportErrorSafely(error);
      return EMPTY;
    }), takeUntil(this._destroy$)).subscribe(() => this._invokeBootstrapOnStates(results.states));
  }
  _invokeInitOnStates(mappedStores) {
    for (const mappedStore of mappedStores) {
      const instance = mappedStore.instance;
      if (instance.ngxsOnChanges) {
        this._store.select(state => getValue(state, mappedStore.path)).pipe(startWith(undefined), pairwise(), takeUntil(this._destroy$)).subscribe(([previousValue, currentValue]) => {
          const change = new NgxsSimpleChange(previousValue, currentValue, !mappedStore.isInitialised);
          instance.ngxsOnChanges(change);
        });
      }
      if (instance.ngxsOnInit) {
        instance.ngxsOnInit(this._getStateContext(mappedStore));
      }
      mappedStore.isInitialised = true;
    }
  }
  _invokeBootstrapOnStates(mappedStores) {
    for (const mappedStore of mappedStores) {
      const instance = mappedStore.instance;
      if (instance.ngxsAfterBootstrap) {
        instance.ngxsAfterBootstrap(this._getStateContext(mappedStore));
      }
    }
  }
  _getStateContext(mappedStore) {
    return this._stateContextFactory.createStateContext(mappedStore);
  }
}
/** @nocollapse */
LifecycleStateManager.ɵfac = function LifecycleStateManager_Factory(t) {
  return new (t || LifecycleStateManager)(i0.ɵɵinject(Store), i0.ɵɵinject(InternalErrorReporter), i0.ɵɵinject(InternalStateOperations), i0.ɵɵinject(StateContextFactory), i0.ɵɵinject(i5.NgxsBootstrapper));
};
/** @nocollapse */
LifecycleStateManager.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: LifecycleStateManager,
  factory: LifecycleStateManager.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LifecycleStateManager, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: Store
    }, {
      type: InternalErrorReporter
    }, {
      type: InternalStateOperations
    }, {
      type: StateContextFactory
    }, {
      type: i5.NgxsBootstrapper
    }];
  }, null);
})();

/**
 * Root module
 * @ignore
 */
class NgxsRootModule {
  constructor(factory, internalStateOperations, _store, _select, states = [], lifecycleStateManager) {
    // Add stores to the state graph and return their defaults
    const results = factory.addAndReturnDefaults(states);
    internalStateOperations.setStateToTheCurrentWithNew(results);
    // Connect our actions stream
    factory.connectActionHandlers();
    // Dispatch the init action and invoke init and bootstrap functions after
    lifecycleStateManager.ngxsBootstrap(new InitState(), results);
  }
}
/** @nocollapse */
NgxsRootModule.ɵfac = function NgxsRootModule_Factory(t) {
  return new (t || NgxsRootModule)(i0.ɵɵinject(StateFactory), i0.ɵɵinject(InternalStateOperations), i0.ɵɵinject(Store), i0.ɵɵinject(SelectFactory), i0.ɵɵinject(ROOT_STATE_TOKEN, 8), i0.ɵɵinject(LifecycleStateManager));
};
/** @nocollapse */
NgxsRootModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxsRootModule
});
/** @nocollapse */
NgxsRootModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsRootModule, [{
    type: NgModule
  }], function () {
    return [{
      type: StateFactory
    }, {
      type: InternalStateOperations
    }, {
      type: Store
    }, {
      type: SelectFactory
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [ROOT_STATE_TOKEN]
      }]
    }, {
      type: LifecycleStateManager
    }];
  }, null);
})();

/**
 * Feature module
 * @ignore
 */
class NgxsFeatureModule {
  constructor(_store, internalStateOperations, factory, states = [], lifecycleStateManager) {
    // Since FEATURE_STATE_TOKEN is a multi token, we need to
    // flatten it [[Feature1State, Feature2State], [Feature3State]]
    const flattenedStates = NgxsFeatureModule.flattenStates(states);
    // add stores to the state graph and return their defaults
    const results = factory.addAndReturnDefaults(flattenedStates);
    if (results.states.length) {
      internalStateOperations.setStateToTheCurrentWithNew(results);
      // dispatch the update action and invoke init and bootstrap functions after
      lifecycleStateManager.ngxsBootstrap(new UpdateState(results.defaults), results);
    }
  }
  static flattenStates(states = []) {
    return states.reduce((total, values) => total.concat(values), []);
  }
}
/** @nocollapse */
NgxsFeatureModule.ɵfac = function NgxsFeatureModule_Factory(t) {
  return new (t || NgxsFeatureModule)(i0.ɵɵinject(Store), i0.ɵɵinject(InternalStateOperations), i0.ɵɵinject(StateFactory), i0.ɵɵinject(FEATURE_STATE_TOKEN, 8), i0.ɵɵinject(LifecycleStateManager));
};
/** @nocollapse */
NgxsFeatureModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxsFeatureModule
});
/** @nocollapse */
NgxsFeatureModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsFeatureModule, [{
    type: NgModule
  }], function () {
    return [{
      type: Store
    }, {
      type: InternalStateOperations
    }, {
      type: StateFactory
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [FEATURE_STATE_TOKEN]
      }]
    }, {
      type: LifecycleStateManager
    }];
  }, null);
})();

/**
 * Ngxs Module
 */
class NgxsModule {
  /**
   * Root module factory
   */
  static forRoot(states = [], options = {}) {
    return {
      ngModule: NgxsRootModule,
      providers: [StateFactory, PluginManager, ...states, ...NgxsModule.ngxsTokenProviders(states, options)]
    };
  }
  /**
   * Feature module factory
   */
  static forFeature(states = []) {
    return {
      ngModule: NgxsFeatureModule,
      providers: [
      // This is required on the feature level, see comments in `state-factory.ts`.
      StateFactory, PluginManager, ...states, {
        provide: FEATURE_STATE_TOKEN,
        multi: true,
        useValue: states
      }]
    };
  }
  static ngxsTokenProviders(states, options) {
    return [{
      provide: USER_PROVIDED_NGXS_EXECUTION_STRATEGY,
      useValue: options.executionStrategy
    }, {
      provide: ROOT_STATE_TOKEN,
      useValue: states
    }, {
      provide: ROOT_OPTIONS,
      useValue: options
    }, {
      provide: APP_BOOTSTRAP_LISTENER,
      useFactory: NgxsModule.appBootstrapListenerFactory,
      multi: true,
      deps: [NgxsBootstrapper]
    }, {
      provide: ɵNGXS_STATE_CONTEXT_FACTORY,
      useExisting: StateContextFactory
    }, {
      provide: ɵNGXS_STATE_FACTORY,
      useExisting: StateFactory
    }];
  }
  static appBootstrapListenerFactory(bootstrapper) {
    return () => bootstrapper.bootstrap();
  }
}
/** @nocollapse */
NgxsModule.ɵfac = function NgxsModule_Factory(t) {
  return new (t || NgxsModule)();
};
/** @nocollapse */
NgxsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxsModule
});
/** @nocollapse */
NgxsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsModule, [{
    type: NgModule
  }], null, null);
})();

/**
 * Decorates a method with a action information.
 */
function Action(actions, options) {
  return (target, name) => {
    // Caretaker note: we have still left the `typeof` condition in order to avoid
    // creating a breaking change for projects that still use the View Engine.
    if (typeof ngDevMode === 'undefined' || ngDevMode) {
      const isStaticMethod = target.hasOwnProperty('prototype');
      if (isStaticMethod) {
        throwActionDecoratorError();
      }
    }
    const meta = ensureStoreMetadata$1(target.constructor);
    if (!Array.isArray(actions)) {
      actions = [actions];
    }
    for (const action of actions) {
      const type = action.type;
      if (!meta.actions[type]) {
        meta.actions[type] = [];
      }
      meta.actions[type].push({
        fn: name,
        options: options || {},
        type
      });
    }
  };
}

/**
 * Decorates a class with ngxs state information.
 */
function State(options) {
  function getStateOptions(inheritedStateClass) {
    const inheritanceOptions = inheritedStateClass[META_OPTIONS_KEY] || {};
    return Object.assign(Object.assign({}, inheritanceOptions), options);
  }
  function mutateMetaData(params) {
    const {
      meta,
      inheritedStateClass,
      optionsWithInheritance
    } = params;
    const {
      children,
      defaults,
      name
    } = optionsWithInheritance;
    const stateName = typeof name === 'string' ? name : name && name.getName() || null;
    // Caretaker note: we have still left the `typeof` condition in order to avoid
    // creating a breaking change for projects that still use the View Engine.
    if (typeof ngDevMode === 'undefined' || ngDevMode) {
      StoreValidators.checkThatStateIsNamedCorrectly(stateName);
    }
    if (inheritedStateClass.hasOwnProperty(META_KEY)) {
      const inheritedMeta = inheritedStateClass[META_KEY] || {};
      meta.actions = Object.assign(Object.assign({}, meta.actions), inheritedMeta.actions);
    }
    meta.children = children;
    meta.defaults = defaults;
    meta.name = stateName;
  }
  return target => {
    const stateClass = target;
    const meta = ensureStoreMetadata$1(stateClass);
    const inheritedStateClass = Object.getPrototypeOf(stateClass);
    const optionsWithInheritance = getStateOptions(inheritedStateClass);
    mutateMetaData({
      meta,
      inheritedStateClass,
      optionsWithInheritance
    });
    stateClass[META_OPTIONS_KEY] = optionsWithInheritance;
  };
}
const DOLLAR_CHAR_CODE = 36;
function createSelectObservable(selector) {
  if (!SelectFactory.store) {
    throwSelectFactoryNotConnectedError();
  }
  return SelectFactory.store.select(selector);
}
function createSelectorFn(name, rawSelector, paths = []) {
  rawSelector = !rawSelector ? removeDollarAtTheEnd(name) : rawSelector;
  if (typeof rawSelector === 'string') {
    const propsArray = paths.length ? [rawSelector, ...paths] : rawSelector.split('.');
    return propGetter(propsArray, SelectFactory.config);
  }
  return rawSelector;
}
/**
 * @example If `foo$` => make it just `foo`
 */
function removeDollarAtTheEnd(name) {
  const lastCharIndex = name.length - 1;
  const dollarAtTheEnd = name.charCodeAt(lastCharIndex) === DOLLAR_CHAR_CODE;
  return dollarAtTheEnd ? name.slice(0, lastCharIndex) : name;
}

/**
 * Decorator for selecting a slice of state from the store.
 */
function Select(rawSelector, ...paths) {
  return function (target, key) {
    const name = key.toString();
    const selectorId = `__${name}__selector`;
    const selector = createSelectorFn(name, rawSelector, paths);
    Object.defineProperties(target, {
      [selectorId]: {
        writable: true,
        enumerable: false,
        configurable: true
      },
      [name]: {
        enumerable: true,
        configurable: true,
        get() {
          return this[selectorId] || (this[selectorId] = createSelectObservable(selector));
        }
      }
    });
  };
}
const SELECTOR_OPTIONS_META_KEY = 'NGXS_SELECTOR_OPTIONS_META';
const selectorOptionsMetaAccessor = {
  getOptions: target => {
    return target && target[SELECTOR_OPTIONS_META_KEY] || {};
  },
  defineOptions: (target, options) => {
    if (!target) return;
    target[SELECTOR_OPTIONS_META_KEY] = options;
  }
};
function setupSelectorMetadata(originalFn, creationMetadata) {
  const selectorMetaData = ensureSelectorMetadata$1(originalFn);
  selectorMetaData.originalFn = originalFn;
  let getExplicitSelectorOptions = () => ({});
  if (creationMetadata) {
    selectorMetaData.containerClass = creationMetadata.containerClass;
    selectorMetaData.selectorName = creationMetadata.selectorName || null;
    getExplicitSelectorOptions = creationMetadata.getSelectorOptions || getExplicitSelectorOptions;
  }
  const selectorMetaDataClone = Object.assign({}, selectorMetaData);
  selectorMetaData.getSelectorOptions = () => getLocalSelectorOptions(selectorMetaDataClone, getExplicitSelectorOptions());
  return selectorMetaData;
}
function getLocalSelectorOptions(selectorMetaData, explicitOptions) {
  return Object.assign(Object.assign(Object.assign(Object.assign({}, selectorOptionsMetaAccessor.getOptions(selectorMetaData.containerClass) || {}), selectorOptionsMetaAccessor.getOptions(selectorMetaData.originalFn) || {}), selectorMetaData.getSelectorOptions() || {}), explicitOptions);
}

/**
 * Decorator for setting selector options at a method or class level.
 */
function SelectorOptions(options) {
  return function decorate(target, methodName, descriptor) {
    if (methodName) {
      descriptor || (descriptor = Object.getOwnPropertyDescriptor(target, methodName));
      // Method Decorator
      const originalFn = descriptor.value || descriptor.originalFn;
      if (originalFn) {
        selectorOptionsMetaAccessor.defineOptions(originalFn, options);
      }
    } else {
      // Class Decorator
      selectorOptionsMetaAccessor.defineOptions(target, options);
    }
  };
}
function ensureStoreMetadata(target) {
  return ensureStoreMetadata$1(target);
}
function getStoreMetadata(target) {
  return getStoreMetadata$1(target);
}
function ensureSelectorMetadata(target) {
  return ensureSelectorMetadata$1(target);
}
function getSelectorMetadata(target) {
  return getSelectorMetadata$1(target);
}
function createSelector(selectors, projector, creationMetadata) {
  const memoizedFn = createMemoizedSelectorFn(projector, creationMetadata);
  const selectorMetaData = setupSelectorMetadata(projector, creationMetadata);
  selectorMetaData.makeRootSelector = createRootSelectorFactory(selectorMetaData, selectors, memoizedFn);
  return memoizedFn;
}
function Selector(selectors) {
  return (target, key, descriptor) => {
    descriptor || (descriptor = Object.getOwnPropertyDescriptor(target, key));
    const originalFn = descriptor === null || descriptor === void 0 ? void 0 : descriptor.value;
    // Caretaker note: we have still left the `typeof` condition in order to avoid
    // creating a breaking change for projects that still use the View Engine.
    if (typeof ngDevMode === 'undefined' || ngDevMode) {
      if (originalFn && typeof originalFn !== 'function') {
        throwSelectorDecoratorError();
      }
    }
    const memoizedFn = createSelector(selectors, originalFn, {
      containerClass: target,
      selectorName: key.toString(),
      getSelectorOptions() {
        return {};
      }
    });
    const newDescriptor = {
      configurable: true,
      get() {
        return memoizedFn;
      }
    };
    // Add hidden property to descriptor
    newDescriptor['originalFn'] = originalFn;
    return newDescriptor;
  };
}
class StateToken {
  constructor(name) {
    this.name = name;
    const selectorMetadata = ensureSelectorMetadata$1(this);
    selectorMetadata.makeRootSelector = runtimeContext => {
      return runtimeContext.getStateGetter(this.name);
    };
  }
  getName() {
    return this.name;
  }
  toString() {
    return `StateToken[${this.name}]`;
  }
}
class NgxsDevelopmentModule {
  static forRoot(options) {
    return {
      ngModule: NgxsDevelopmentModule,
      providers: [NgxsUnhandledActionsLogger, {
        provide: NGXS_DEVELOPMENT_OPTIONS,
        useValue: options
      }]
    };
  }
}
/** @nocollapse */
NgxsDevelopmentModule.ɵfac = function NgxsDevelopmentModule_Factory(t) {
  return new (t || NgxsDevelopmentModule)();
};
/** @nocollapse */
NgxsDevelopmentModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxsDevelopmentModule
});
/** @nocollapse */
NgxsDevelopmentModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxsDevelopmentModule, [{
    type: NgModule
  }], null, null);
})();
function ensureValidSelector(selector, context = {}) {
  const noun = context.noun || 'selector';
  const prefix = context.prefix ? context.prefix + ': ' : '';
  ensureValueProvided(selector, {
    noun,
    prefix: context.prefix
  });
  const metadata = getSelectorMetadata$1(selector) || getStoreMetadata$1(selector);
  if (!metadata) {
    throw new Error(`${prefix}The value provided as the ${noun} is not a valid selector.`);
  }
}
function ensureValueProvided(value, context = {}) {
  const noun = context.noun || 'value';
  const prefix = context.prefix ? context.prefix + ': ' : '';
  if (!value) {
    throw new Error(`${prefix}A ${noun} must be provided.`);
  }
}
function createModelSelector(selectorMap) {
  const selectorKeys = Object.keys(selectorMap);
  const selectors = Object.values(selectorMap);
  ensureValidSelectorMap({
    prefix: '[createModelSelector]',
    selectorMap,
    selectorKeys,
    selectors
  });
  return createSelector(selectors, (...args) => {
    return selectorKeys.reduce((obj, key, index) => {
      obj[key] = args[index];
      return obj;
    }, {});
  });
}
function ensureValidSelectorMap({
  prefix,
  selectorMap,
  selectorKeys,
  selectors
}) {
  ensureValueProvided(selectorMap, {
    prefix,
    noun: 'selector map'
  });
  ensureValueProvided(typeof selectorMap === 'object', {
    prefix,
    noun: 'valid selector map'
  });
  ensureValueProvided(selectorKeys.length, {
    prefix,
    noun: 'non-empty selector map'
  });
  selectors.forEach((selector, index) => ensureValidSelector(selector, {
    prefix,
    noun: `selector for the '${selectorKeys[index]}' property`
  }));
}
function createPickSelector(selector, keys) {
  ensureValidSelector(selector, {
    prefix: '[createPickSelector]'
  });
  const validKeys = keys.filter(Boolean);
  const selectors = validKeys.map(key => createSelector([selector], s => s[key]));
  return createSelector([...selectors], (...props) => {
    return validKeys.reduce((acc, key, index) => {
      acc[key] = props[index];
      return acc;
    }, {});
  });
}
function createPropertySelectors(parentSelector) {
  ensureValidSelector(parentSelector, {
    prefix: '[createPropertySelectors]',
    noun: 'parent selector'
  });
  const cache = {};
  return new Proxy({}, {
    get(_target, prop) {
      const selector = cache[prop] || createSelector([parentSelector], s => s === null || s === void 0 ? void 0 : s[prop]);
      cache[prop] = selector;
      return selector;
    }
  });
}

/**
 * The public api for consumers of @ngxs/store
 */

/**
 * Generated bundle index. Do not edit.
 */

export { Action, Actions, InitState, NGXS_PLUGINS, NgxsDevelopmentModule, NgxsModule, NgxsSimpleChange, NgxsUnhandledActionsLogger, NoopNgxsExecutionStrategy, Select, Selector, SelectorOptions, State, StateStream, StateToken, Store, UpdateState, actionMatcher, createModelSelector, createPickSelector, createPropertySelectors, createSelector, ensureSelectorMetadata, ensureStoreMetadata, getActionTypeFromInstance, getSelectorMetadata, getStoreMetadata, getValue, ofAction, ofActionCanceled, ofActionCompleted, ofActionDispatched, ofActionErrored, ofActionSuccessful, setValue, NgxsFeatureModule as ɵNgxsFeatureModule, NgxsRootModule as ɵNgxsRootModule };
